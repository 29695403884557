import { gqlOpenQuery, gqlquery } from "../../api";
import { gqlquery as hospitalquery } from "../../api/hospitalIndex";
import { ExperienceData } from "../../commonfunctions/commonValues";

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "Windows Desktop";
};

export const handleApplyForAJob = async (vacancyID) => {
    const QUERY_APPLYFORJOB = {
        query: `mutation MyMutation {
            applyForAJob (vacancyID: ${Number(vacancyID)})
        }`,
        variables: null,
        operationName: "MyMutation",
    };

    return await gqlquery(QUERY_APPLYFORJOB, null)
        .then((res) => res.json())
        .then((data) => data)
};

export const handleSaveAJob = async (vacancyID) => {
    const QUERY_SAVEAJOB = {
        query: `mutation MyMutation {
            saveAJob (vacancyID: ${Number(vacancyID)})
        }`,
        variables: null,
        operationName: "MyMutation",
    };

    return await gqlquery(QUERY_SAVEAJOB, null)
        .then((res) => res.json())
        .then((data) => data);
};

export const handleDeleteASavedJob = async (vacancyID) => {
    const QUERY_REMOVEJOBFROMSAVEDLIST = {
        query: `mutation MyMutation {
            removeJobFromSavedList (vacancyID: ${Number(vacancyID)})
        }`,
        variables: null,
        operationName: "MyMutation",
    };

    return await gqlquery(QUERY_REMOVEJOBFROMSAVEDLIST, null)
        .then((res) => res.json())
        .then((data) => data);
};

export const firstCharCap = (str) => {
    return str.split(" ").map(string => string.slice(0, 1).toUpperCase() + string.slice(1, string.length)).join(" ")
};

export const splitString = (string) => {
    if (!string?.length) return;
    const arr = string.split(',');

    const result = [];
    for (let i = 0; i < arr.length; i += 2) {
        const first = arr[i]?.trim();
        const second = arr[i + 1]?.trim();
        const combined = `${first}, ${second}`;
        result.push(combined);
    }

    return result;
};

export const formatString = (inputStr) => {
    // If input string is empty or undefined, return an empty string
    if (!inputStr || inputStr?.trim().length === 0) {
        return "";
    }

    // Remove any commas from the input string
    inputStr = inputStr?.replace(/,/g, "");

    // Remove any unnecessary spaces
    inputStr = inputStr?.replace(/\s+/g, " ")?.trim();

    // Split the input string into words
    const words = inputStr.split(" ");

    // If there is only one word, return it as is
    if (words.length === 1) {
        return words[0];
    }

    // Get the state name (last word)
    const state = words[words.length - 1];

    // Get the city/town names (all words except the last one)
    const cityNames = words.slice(0, words.length - 1);

    // Join the city/town names with dashes and add the state name
    const formattedStr = cityNames.join("-") + "-" + state;

    // Return the formatted string
    return formattedStr.toLowerCase();
};

export function extractKeywords(str) {
    if (str[0] === '/') {
        str = str.slice(1);
    }
    let firstKeyword = '';
    let secondKeyword = '';
    if (str.includes('-jobs-in-')) {
        const [first, second] = str.split('-jobs-in-');
        firstKeyword = first.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        secondKeyword = second.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else if (str.includes('-jobs')) {
        firstKeyword = str.split('-jobs')[0].split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else {
        firstKeyword = str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    return {
        firstKeyword,
        secondKeyword
    };
};

export function convertToHyphenatedString(str) {
    // Replace special characters and non-alphanumeric characters with hyphens
    const hyphenated = str?.replace(/[^a-zA-Z0-9]+/g, "-").replace(/\\+/g, "-");

    // Remove consecutive hyphens
    const noConsecutiveHyphens = hyphenated?.replace(/-{2,}/g, "-");

    // Remove leading and trailing hyphens
    const trimmed = noConsecutiveHyphens?.replace(/^-+|-+$/g, "");

    return trimmed;
};

export const formatSalary = (minimumSalary, maximumSalary) => {
    if ((minimumSalary / 100000 === 0 && maximumSalary / 100000 === 0) || minimumSalary == null || maximumSalary == null) {
        return "Not Disclosed";
    } else {
        const formattedMinimumSalary = (minimumSalary < 1)
            ? minimumSalary.toFixed(minimumSalary.toString().split('.')[1].length)
            : (minimumSalary / 100000).toFixed(2);

        const formattedMaximumSalary = (maximumSalary < 1)
            ? maximumSalary.toFixed(maximumSalary.toString().split('.')[1].length)
            : (maximumSalary / 100000).toFixed(2);

        return `${formattedMinimumSalary} - ${formattedMaximumSalary > 1
            ? `${formattedMaximumSalary} Lakhs`
            : `${formattedMaximumSalary} Lakh`}`;
    }
};

export const getRecruiterLogo = async (job) => {
    const QUERY_DOWNLOADHOSPITALLOGO = {
        query: `query MyQuery {
            getHospitalLogo(
                hospitalID: "${job?.hospitalID}",
                logo: "${job?.logo || ""}"
            )
          }`,
    };
    return await gqlOpenQuery(QUERY_DOWNLOADHOSPITALLOGO, null)
        .then((res) => res.json())
        .then((data) => {
            const hospitalLogo = JSON.parse(data?.data?.getHospitalLogo)?.response?.content || "";
            const logoData = `data:image/png;base64,${hospitalLogo}`;
            return { ...job, logo: logoData };
        });
};

export const isAppliedToJob = async (vacancyID) => {
    if (!localStorage.getItem("accessToken")) return;
    const QUERY_ISJOBSAVEDORAPPLIED = {
        query: `query MyQuery {
            isJobApplied(vacancyID: ${Number(vacancyID)}) 
            {
               appliedAt
               jaID
               savedJob
               appliedJob
               vacancyID
            }
        }`,
        variables: null,
        operationName: "MyMutation",
    };

    return await gqlquery(QUERY_ISJOBSAVEDORAPPLIED, null)
        .then((res) => res.json())
        .then((data) => data?.data?.isJobApplied);
}

export const getAllLocations = async (event) => {
    const val = event.target.value.split(" ").length - 1;
    const valtwo = event.target.value.length - val;
    let value = (event.target.value).replace(/^\s+/, "")

    if (
        event.target.value &&
        event.target.value !== " " &&
        event.target.value !== "" &&
        valtwo >= 2
    ) {
        const GET_CITY = {
            query: `query MyQuery {
          searchCity(city: "${value}") {
            city
            cityWithState
            country
            lmID
            state
          }
        }
      `,
            variables: null,
            operationName: "MyQuery",
        };

        return await gqlOpenQuery(GET_CITY, null)
            .then((res) => res.json())
            .then((datas) => datas?.data?.searchCity);
    } else {
        return [];
    }
};
export const searchAllCourse = async (event, selectedQualification) => {

    const GET_COURSE = {
        query: `query MyQuery {
                getEducationCourses(course:"${event.target.value}", qualification: "${selectedQualification?.qualification}") {
                  course
                  emID
                }
              }
      `,
        variables: null,
        operationName: "MyQuery",
    };

    return await hospitalquery(GET_COURSE, null)
        .then((res) => res.json())
        .then((datas) => datas?.data?.getEducationCourses);

}

export const date_difference = function (date) {
    const dt1 = new Date(date);
    const dt2 = new Date();
    const days = Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
            Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    const month = Math.floor(days / 30)
    const day = days % 30
    if (days >= 30) {

        return {
            month,
            day
        }
    }
    return {
        days
    }
};

export const formatDateDMY = (date) => {
    return `${new Date(date)?.toLocaleString(
        "default",
        { day: "2-digit" }
    )}-${new Date(date)?.toLocaleString(
        "default",
        { month: "short" }
    )}-${new Date(date)?.toLocaleString(
        "default",
        { year: "numeric" }
    )}`;
};

export function formatTimeToAMPM(timeString) {
    const formattedTime = new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });

    return formattedTime;
}

export const getJobRoleAndDepartment = async (job) => {
    const QUERY_GETJOBROLEANDDEPARTMENT = {
        query: `query MyQuery {
            getJobRoleAndDepartment(vacancyID: ${Number(job?.vacancyID)}) {
              department
              jobRole
            }
          }
        `,
        variables: null,
        operationName: "MyMutation",
    };
    return await gqlOpenQuery(QUERY_GETJOBROLEANDDEPARTMENT, null)
        .then((res) => res.json())
        .then((data) => data?.data?.getJobRoleAndDepartment);
};

export function findIndex(array, value) {
    return array.indexOf(value);
}

export const splitvaluebycomma = async (inputString) => {
    const words = inputString.split(',');
    if (words.length > 1) {
        const wordBeforeComma = words[0].trim();
        return await wordBeforeComma
    } else {
        return
    }
}
export const checkAllValuesAreFalse = (obj) => {
    return Object.values(obj).every((value) => !value);
};

export const getMatchedExperience = (exp) => {
    const matched = ExperienceData?.filter(data => data?.value === exp)
    return matched;
}