const ROUTES = {
    HOME: "/home",
    SIGNUP: "/jobseeker-signup",
    SIGNUP2: "/jobseeker-registration",
    CREATE_NEW_PASSWORD: "/jobseeker-create-new-password",
    LOGIN: "/jobseeker-login",
    PROFILE: "/profile",
    USERLIST: "/userslist",
    POSTPRIVATEJOB: "/post-private-job",
    MANAGEJOBSANDRESPONSES: "/manage-jobs-and-responses",
    CREATEVACANCIES: "/create-vacancies",
    POSTJOB: "/post-job",
    JOBTITLE: "/:jobtitle/:vacancyID",
    CREDITEXPIRED: "/credit-expired",
    REGISTERHOSPITAL: "/recruiter-registration",
    HOSPITALSIGNUP: "/recruiters-signup/:loginCode",
    HOSPITALSIGNUP1: "/recruiters-signup",
    HOSPITALLOGIN: "/recruiters-login",
    HOSPITALDASHBOARD: "/hospital-dashboard",
    APPLICANTDETAILS: "/applicants-detail/:userID",
    APPLICANTDETAILS2: "/applicants-detail/:jaID",
    SAVEDSEARCHES: "/saved-searches",
    PERSONALFOLDERS: "/personal-folders",
    NOTIFICATIONS: "/notifications",
    REMINDERS: "/recruiter-reminder",
    SUBSCRIPTIONSTATUS: "/subscription-status",
    COMPANYPROFILE: "/company-setting",
    RECRUITERACCOUTSETTING: "/account-setting",
    ADVANCESEARCH: "/advance-search",
    SEARCHRESUMERESULT: "/search-resume-result",
    ACCOUNTDETAILS: "/profile/account-details",
    PRICINGPLANS: "/pricing",
    CHECKOUTPLAN: "/checkout-plan",
    JOBSEARCHLIST: "/:jobtitle",
    SINGLEJOBSEARCH: "/job/:jobtitle/:vacancyID/:lastchangeddate",
    FORGOTPASSWORD: "/forgot-password",
    CREATENEWPASSWORD: "/create-new-password",
    CONTACTUS: "/contact-us",
    BASICDETAILS: "/basic-details",
    PROFILEHOME: "/profile-home",
    SAVEDJOBS: "/saved-jobs",
    ACCOUNTSETTINGS: "/account-settings",
    PROFILELISTFOLDER: "/profile-list-folder/:id",
    FEATUREDHOSPITAL: "/featured-hospital",
    EDITBASICDETAILS: "/edit-basic-details",
    PRIMARYHOME: "/home",
    FAQ: "/faq",
    MANAGEALERT: "/manage-alert",
    ABOUTUS: "/about-us",
    TERMSANDUSAGE: "/terms-and-services",
    PRIVACYPOLICY: "/privacy-policy",
    PAYMENTSTATUS: "/payment-status",
    JOBSEEKERFEATURE: "/jobseeker-features",
    JOBSEEKERSSEARCHJOBS: "/search-jobs",
    RECRUITERFEATURE: "/recruiter-features",
    CREATEPROFILE: "/create-profile",
    REGISTEREDUCATION: "/register-education",
    REGISTEREXPERIENCE: "/register-experience",
    REGISTERPREFERENCE: "/register-preference",
    FEATURECOMINGSOON: "/feature-coming-soon",
    REFUNDCANCELLATIONPOLICY: "/refund-and-cancellation-policy",
    HOWTOPURCHASE: "/how-to-purchase",
    TEAM: "/team-medlink",
    PRESSANDMEDIA: "/press-and-media",
    ADDBASICDETAILS: "/add-basic-details",
    POSTPREMIUMJOBMEDLINKSYSTEMUSER: "post-premium-job-team-medlink",
    BLOGS: "/blog/:category",
    BLOG: "/medlink-blog",
    BLOGDETAILS: "/blog/:blogId/:blogtitle",
    JOBSEEKERNOTIFICATIONS: "/jobseeker-notifications",
    LANDINGPAGE: "/healthcare-jobs/:keyword-jobs",
    JOBPOSTPRICING: "/job-posting-pricing",
    APPLIEDJOBS: "/applied-jobs",
    RECRUITERFEATURESPOSTAJOB: "/post-jobs",
    RECRUITERFEATURESBRANDINGSOLUTIONS: "/branding-solutions",
    RECRUITERFEATURESSEARCHCANDIDATE: "/search-candidate",
    RECRUITERSSTAFFINGSOLUTIONS: "/staffing-solutions",
    RECRUITERFEATURESSEARCHCANDIDATEFORM: "/search-candidate-enquiry",
    RECRUITERSSTAFFINGSOLUTIONSFORM: "/staffing-solutions-enquiry",
    RECRUITERFEATURESBRANDINGSOLUTIONSFORMS: "/branding-solutions-enquiry",
    RECRUITERFEATURESFORMSUBMISSIONSUCCESS: "/success",
    CAMPAIGNHOMEPAGE: "/jobs",
    DownloadApp: "/download-app",
    RECRUITERHOME: '/recruiter-home',
    COURSE: 'course/:courseName',
    SAVEDJOBSEEKERPROFILE: "/saved-JobSeeker-Profiles",
    JOBROLE: '/jobrole/:jobRole'
};

export default ROUTES;
