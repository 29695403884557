import { GET_CITY, GET_INDUSTRY, GET_INSTITUTE, GET_INSTITUTE_MASTER, GET_QUALIFICATIONS, GET_SKILL_MASTER, GET_STATE_MASTER, QUERY_DEPARTMENTS, QUERY_GETALLDESIGNATIONS, QUERY_GETEDUCATION, QUERY_GETEXPERIENCELIST, QUERY_GETPERSONALDETAILS, QUERY_GETRESUME, QUERY_GETRESUMEHEADLINE, QUERY_GET_APPLIED_JOBS, QUERY_GET_RESUME_HEADLINE_SUGGESTION, QUERY_GET_TRENDING_JOBS, QUERY_NOTICEMASTER, QUERY_SAVEDJOBS, QUERY_UNIVERSITYID, gqlOpenQuery, gqlquery } from "../../../../api";
import { getJobRoleAndDepartment, getRecruiterLogo, isAppliedToJob } from "../../CommonFunctions";

export const getVerifyEmailInfo = async (value) => {
  const GET_VERIFY_EMAILID = {
    query: `query MyQuery {
           verifyEmail(emailID: "${value}")
         }`,
  };

  return await gqlOpenQuery(GET_VERIFY_EMAILID, null)
    .then((res) => res.json())
    .then((datas) => JSON.parse(datas?.data?.verifyEmail));
};

export const getCity = async () => {
  return await gqlquery(GET_CITY, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getCityMaster));
}

export const getAllQualification = async () => {

  return await gqlquery(GET_QUALIFICATIONS, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getQualification));
}

export const getAllCourses = async (values) => {
  const GET_COURSES = {
    query: `query MyQuery {
          getCourse(industry: "Other", qualification: "${values?.qualification}") {
            course
          }
        }
      `,
    variables: null,
    operationName: "MyQuery",
  };
  return await gqlquery(GET_COURSES, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getCourse));
}

export const getAllspecialization = async (values, val) => {
  const GET_SPECIALIZATION = {
    query: `query MyQuery {
          getSpecialization(course: "${values?.course}", industry: "Other", qualification: "${values?.qualification}", specialization: "${val ? val : ""}") {
            course
            emID
            healthcareIndustry
            qualification
            specialization
          }
        }
      `,
    variables: null,
    operationName: "MyQuery",
  };

  return await gqlquery(GET_SPECIALIZATION, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getSpecialization));

}
export const getNoticeMaster = async () => {
  return await gqlquery(QUERY_NOTICEMASTER, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getNoticePeriodMasters))
}

export const getAllUniversities = async () => {
  return await gqlquery(QUERY_UNIVERSITYID, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getUniversityMaster));
}

export const searchUniversities = async (value) => {
  const GET_UNIVERSITY = {
    query: `query MyQuery {
          getUniversityColleges(university: "${value}") {
            name
            umID
          }
        }
      `,
    variables: null,
    operationName: "MyQuery",
  };
  return await gqlquery(GET_UNIVERSITY, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getUniversityColleges));
}

export const getMasterSkills = async () => {
  return await gqlquery(GET_SKILL_MASTER, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getSkillMaster))
}

export const searchSkill = async (value) => {
  const GET_SKILLS = {
    query: `query MyQuery {
            searchSkill(name: "${value}") {
            name
            skillID
          }
        }
      `,
    variables: null,
    operationName: "MyQuery",
  };

  return await gqlquery(GET_SKILLS, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.searchSkill));
}

export const getAllDesignations = async (value) => {
  const QUERY_GETALLDESIGNATIONS = {
    query: `query MyQuery {
            getHCISpecialty(specialty: "${value ? value : ""}") {
              hciID 
              specialty 
            }
          }`,
    variables: null,
    operationName: "MyQuery",
  }
  return await gqlquery(QUERY_GETALLDESIGNATIONS, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getHCISpecialty))
}

export const getHospitalorCompany = async () => {
  return await gqlquery(GET_INSTITUTE_MASTER, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getHealthInstituteMaster))
}

export const searchHospitalorCompany = async (value) => {
  const SEARCH_INSTITUTE = {
    query: `query MyQuery {
          getHealthInstitutes(institute: "${value}") {
            himID
            name
          }
        }
      `,
    variables: null,
    operationName: "MyQuery",
  };

  return await gqlquery(SEARCH_INSTITUTE, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getHealthInstitutes))
}

export const getAllIndustry = async () => {
  return await gqlquery(GET_INDUSTRY, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getHCIIndustry))
}

export const searchIndustries = async (value) => {
  const SEARCH_INDUSTRY = {
    query: `query MyQuery {
      getHCIIndustry(industry:"${value}") {
        hciID
        industry
        specialty
      }
    }`,
    variables: null,
    operationName: "MyQuery",
  };
  return await gqlquery(SEARCH_INDUSTRY, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getHCIIndustry))
}
export const getAllDepartment = async () => {
  return await gqlquery(QUERY_DEPARTMENTS, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.getDepartments))
}

export const searchDepartment = async (value) => {
  const QUERY_SEARCH_DEPARTMENTS = {
    query: `query MyQuery {
          searchDepartment(name: "${value}") {
            departmentID
            name
          }
        }`
  }
  return await gqlquery(QUERY_SEARCH_DEPARTMENTS, null)
    .then((res) => res.json())
    .then((datas) => (datas?.data?.searchDepartment))
}

export const getStates = async () => {
  return await gqlquery(GET_STATE_MASTER, null)
    .then((res) => res.json())
    .then((datas) => datas?.data?.getStateMaster)
}

export const getPersonalDetails = async () => {
  return await gqlquery(QUERY_GETPERSONALDETAILS, null)
    .then((res) => res.json())
    .then((datas) => datas?.data?.getPersonalDetails)
}

export const getEducationDetails=async()=>{
  return await gqlquery(QUERY_GETEDUCATION,null)
  .then((res)=> res.json())
  .then((datas)=>(datas?.data?.getEducationList[0]))
}

export const getResumeInfo = async () => {
  return await gqlquery(QUERY_GETRESUME, null)
    .then((res) => res.json())
    .then((datas) => datas?.data?.getResume);
};

export const getResumeHeadelineSuggestionInfo = async () => {
  return await gqlquery(QUERY_GET_RESUME_HEADLINE_SUGGESTION, null)
    .then((res) => res.json())
    .then((data) => data?.data?.getResumeHeadlineSuggestion);
};

export const getResumeHeadelineInfo = async () => {
  return await gqlquery(QUERY_GETRESUMEHEADLINE, null)
    .then((res) => res.json())
    .then((datas) => datas?.data?.getResume);
};

export const getSavedJobs = async () => {
  const res = await gqlquery(QUERY_SAVEDJOBS, null);
  const savedJobs = await res.json();
  return savedJobs?.data?.getSavedJobs;
};

export const getAppliedJobs = async () => {
  const res = await gqlquery(QUERY_GET_APPLIED_JOBS, null);
  const appliedJobs = await res.json();
  return appliedJobs?.data?.getAppliedJobs;
};

export const fetchJobDetails = async (job) => {
  const { logo } = await getRecruiterLogo(job) || {};
  const jobRoleDepartment = await getJobRoleAndDepartment(job);
  const info = await isAppliedToJob(job?.vacancyID);
  return { ...job, logo, ...jobRoleDepartment, ...info };
};

export const getTrendingJobsWithLogosAndStatus = async () => {
  const res = await gqlOpenQuery(QUERY_GET_TRENDING_JOBS, null);
  const data = await res.json();
  const trendingJobs = data?.data?.getTrendingJobs;
  const getAllInfo = trendingJobs?.map(fetchJobDetails);
  return await Promise.all(getAllInfo);
};

export const getSavedJobsWithLogosAndStatus = async () => {
  const savedJobs = await getSavedJobs();
  const getAllInfo = savedJobs?.map(fetchJobDetails);
  return await Promise.all(getAllInfo);
};

export const getAppliedJobsWithLogosAndStatus = async () => {
  const appliedJobs = await getAppliedJobs();
  const getAllInfo = appliedJobs?.map(fetchJobDetails);
  return await Promise.all(getAllInfo);
};

export const handleCheckEmailExistence = async (emailAddress) => {
  const CHECK_EMAIL_EXISTS = {
    query: `query MyQuery{
          checkEmailExists(email:"${emailAddress}")
        }`,
  };

  return await gqlOpenQuery(CHECK_EMAIL_EXISTS, null)
    .then((res) => res.json())
    .then(({ data }) => {
      const response = JSON.parse(data["checkEmailExists"]);
      const exists = response.length > 0 ? true : false;
      return exists;
    });
};

export const handleCheckPhoneExistence = async (phoneNum) => {
  const CHECK_PHONE_EXISTS = {
    query: `query MyQuery{
                checkPhoneExists(phone:"${phoneNum}")
            }`,
  };

  return await gqlOpenQuery(CHECK_PHONE_EXISTS, null)
    .then((res) => res.json())
    .then(({ data }) => {
      const response = JSON.parse(data["checkPhoneExists"]);
      const exists = response.length > 0 ? true : false;
      return exists;
    });
};

export const getJobseekerExperience = async () => {
  return await gqlquery(QUERY_GETEXPERIENCELIST, null)
    .then((res) => res.json())
    .then((datas) => datas?.data?.getExperienceList)
}

export const getSkillByExpID = async (expID) => {
  const GET_SKILLS_BY_EXP_ID = {
    query: `query MyQuery {
      getSkillsByExpID(expID: ${Number(expID)}) {
        name
        skillID
      }
    }`,
    variables: null,
    operationName: "MyQuery",
  };

  return await gqlquery(GET_SKILLS_BY_EXP_ID, null)
    .then((res) => res.json())
    .then((data) => data?.data?.getSkillsByExpID);
}

export const getSkillByEDUID = async (eduID) => {
  const GET_SKILLS_BY_EDU_ID = {
    query: `query MyQuery {
        getFresherSkillsByEduID(eduID: ${Number(eduID)}) {
          name
          skillID
        }
      }`,
    variables: null,
    operationName: "MyQuery",
  };

  return await gqlquery(GET_SKILLS_BY_EDU_ID, null)
    .then((res) => res.json())
    .then((data) => data?.data?.getFresherSkillsByEduID);
}

export const getDepartmentByDepartmentID = async (departmentID) => {
  const GET_DEPARTMENT_BY_DEPARTMENT_ID = {
    query: `query MyQuery {
      getDepartmentByID(departmentID: ${departmentID}) {
        departmentID
        name
        status
      }
    }`,
    variables: null,
    operationName: "MyQuery",
  };

  return await gqlquery(GET_DEPARTMENT_BY_DEPARTMENT_ID, null)
    .then((res) => res.json())
    .then((data) => data?.data?.getDepartmentByID);
};
