import { API, graphqlOperation } from "aws-amplify";
 import useUserState from "../../hooks/useUserState";

export const RecruiterSubscriptions = ({
  getNotifications,
  notifications,
  permitUser,
  getReminders,
  getHospitalDetailsData,
  getUserProfile
}) => {
    getUserProfile()
   const SUB_ON_PUBLISH_RECRUITER_NOTIFICATIONS = `subscription MySubscription {
        onPublishRecruiterNotification(hospitalID: "${permitUser?.hospitalID}") {
          createdAt
          description
          hospitalID
          isRead
          redirectTo
          rnID
          title
          type
          userID
        }
      }`;
   const subscription = API.graphql(
    graphqlOperation(SUB_ON_PUBLISH_RECRUITER_NOTIFICATIONS)
  ).subscribe({
    next: ({ provider, value }) => {
      try {
        if (value?.data?.onPublishRecruiterNotification) {
          getNotifications();
        }
      } catch (err) {
        console.log(err);
      }
    },

    error: (error) => console.log(error),
  });
   const SUB_ON_PUBLISH_HOURLY_REMINDERS = `subscription MySubscription {
    onPublishHourlyReminder
  }`;

  API.graphql(graphqlOperation(SUB_ON_PUBLISH_HOURLY_REMINDERS)).subscribe({
    next: ({ provider, value }) => {
      try {
        getReminders();
      } catch (err) {
        console.log(err);
      }
    },

    error: (error) => console.log(error),
  });
   const SUB_ON_PUBLISH_DAILY_REMINDERS = `subscription MySubscription {
                onPublishDailyReminder {
                  deviceToken
                  email
                  hospitalID
                  jobSeekerUserID
                  name
                  recruiterName
                  userID
                }
              }`;

  API.graphql(graphqlOperation(SUB_ON_PUBLISH_DAILY_REMINDERS)).subscribe({
    next: ({ provider, value }) => {
      try {
        getReminders();
      } catch (err) {
        console.log(err);
      }
    },

    error: (error) => console.log(error),
  });
  
  };
