import { Amplify, Auth, Hub } from "aws-amplify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { gqlOpenQuery } from "../../api";
import { gqlquery, QUERY_GETHOSPITAL } from "../../api/hospitalIndex";
import ChevronLeftIconsvg from "../../assets/images/ChevronLeftIconsvg.svg";
import useApplicationState from "../../hooks/useApplicationState";
import { requestForToken } from "../../services/firebase";
import "./HospitalSignUpForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useRecaptcha } from "../../hooks/useRecaptcha";


const SignupGrid = styled(Grid)(() => ({
  backgroundColor: "var(--clr-white)",
  // boxShadow: "0px 9px 18px rgba(69, 143, 246, 0.09)",
  borderRadius: "6px",
  // padding: "20px 39px 40px",
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

var __currentUserEmail = "";

// rendering continuously in hospital flow and job seekers flow

const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const HospitalSignUpForm = (props) => {
  const navigate = useNavigate(false);
  const location = useLocation();
  const [loader, setLoader] = useState();
  const [error, setError] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [errorInvalidEmail, setErrorInvalidEmail] = useState("");
  const [strongPassword, setStrongPassword] = useState("");
  const [weakPassword, setWeakPassword] = useState("");
  const [failure, setFailure] = useState(false);
  const [isLogin, setisLogin] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmpassword: "",
    forgotPasswordCode: "",
  });
  const [recievedCode, setRecievedCode] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [success, setSuccess] = useState("");
  const [successForgetPassword, setSuccessForgetPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [failureLogin, setFailureLogin] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [confirmEmailCode, setConfirmEmailCode] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  });
  const [forgotPassword, setForgotPassword] = useState("");
  const [emailByLink, setEmaiBylLink] = useState([]);
  const [emailCode, setEmailCode] = useState("");
  const [progress, setProgress] = useState(0);
  const [userExists, setUserExists] = useState(false);
  const [userExistsData, setUserExistsData] = useState();
  const { getUserProfile,NavigateHistory } = useApplicationState();
  const { loginCode } = useParams();
  const [signupResendCode, setSignupResendCode] = useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [focusedPassword, setFocusedPassword] = useState(false);
  const [focusedConfirmPassword, setFocusedConfirmPassword] = useState(false);
  const [focusedSignUpVerificationCode, setFocusedSignUpVerificationCode] =
    useState(false);
  const [
    focusedForgotPasswordVerificationCode,
    setFocusedForgotPasswordVerificationCode,
  ] = useState(false);
  const [focusedForgetPassword, setFocusedForgetPassword] = useState(false);
  const [focusedForgetConfirmPassword, setFocusedForgetConfirmPassword] =
    useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [code1Ref, setCode1Focus] = UseFocus();
  const [code2Ref, setCode2Focus] = UseFocus();
  const [code3Ref, setCode3Focus] = UseFocus();
  const [code4Ref, setCode4Focus] = UseFocus();
  const [code5Ref, setCode5Focus] = UseFocus();
  const [code6Ref, setCode6Focus] = UseFocus();
  const [confirmBtnFocus, setConfirmBtnFocus] = UseFocus();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const {
    attempts,
    setAttempts,
    showRecaptcha,
    handleCountForRecaptcha,
    handleRecaptchaChange,
  } = useRecaptcha();

  const token = localStorage.getItem("accessToken");
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (((location?.state?.history === "/post-jobs") || (location?.state?.history === "/search-candidate") || (location?.state?.history === "/staffing-solutions") || (location?.state?.history === "/branding-solutions"))) {
      setOpen1(true);
    };
  }, [location?.state?.history])

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleConfirmationCode = (prop) => (event) => {
    const val = event.target.value;
    if (val.length === 6) {
      setConfirmEmailCode({
        ...confirmEmailCode,
        code1: val?.slice(0, 1),
        code2: val?.slice(1, 2),
        code3: val?.slice(2, 3),
        code4: val?.slice(3, 4),
        code5: val?.slice(4, 5),
        code6: val?.slice(5, 6),
      });
    } else {
      setConfirmEmailCode({
        ...confirmEmailCode,
        [prop]: val?.slice(val.length - 1, val.length),
      });
    }

    switch (prop) {
      case "code1":
        // if(!val) setCode2Focus();
        if (val) setCode2Focus();
        break;
      case "code2":
        if (!val) setCode1Focus();
        if (val) setCode3Focus();
        break;
      case "code3":
        if (!val) setCode2Focus();
        if (val) setCode4Focus();
        break;
      case "code4":
        if (!val) setCode3Focus();
        if (val) setCode5Focus();
        break;
      case "code5":
        if (!val) setCode4Focus();
        if (val) setCode6Focus();
        break;
      case "code6":
        if (!val) setCode5Focus();
        if (val) setCode5Focus();
        break;
      default:
        setConfirmBtnFocus();
    }
  };

  const focusChangeKeyDown = (prop) => (event) => {
    var key = event.keyCode;
    if (key === 8) {
      switch (prop) {
        case "code1":
          if (confirmEmailCode.code1 === "") {
            return setCode1Focus();
          } else {
            return setCode2Focus();
          }
          break;
        case "code2":
          if (confirmEmailCode.code2 === "") {
            return setCode1Focus();
          } else {
            return setCode2Focus();
          }
          break;
        case "code3":
          if (confirmEmailCode.code3 === "") {
            return setCode2Focus();
          } else {
            return setCode3Focus();
          }
          break;
        case "code4":
          if (confirmEmailCode.code4 === "") {
            return setCode3Focus();
          } else {
            return setCode4Focus();
          }
          break;
        case "code5":
          if (confirmEmailCode.code5 === "") {
            return setCode4Focus();
          } else {
            return setCode5Focus();
          }
          break;
        case "code6":
          if (confirmEmailCode.code6 === "") {
            return setCode5Focus();
          } else {
            return setCode6Focus();
          }
          break;
        default:
          setConfirmBtnFocus();
      }
    }
  };

  const checkEmailAlreadyExists = () => {
    return new Promise((resolve, reject) => {
      const QUERY_GET_EMAIL_EXISTS = {
        query: `query MyQuery {
                checkUserExists(email: "${values.email}")
                  }`,
        operationName: "MyQuery",
      };

      gqlOpenQuery(QUERY_GET_EMAIL_EXISTS, null)
        .then((res) => res.json())
        .then((datas) => {
          const existsData = datas.data.checkUserExists;
          resolve(existsData);
        });
    });
  };

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: "ap-south-1",
        userPoolId: `${process.env.REACT_APP_HOSPITAL_FLOW_SIGNUP_USERPOOLID}`,
        userPoolWebClientId: `${process.env.REACT_APP_HOSPITAL_FLOW_SIGNUP_CLIENTID}`,
      },
    });

  }, [])

  useEffect(() => {
    if (recievedCode) {
      if (!confirmEmailCode.code1) return setCode1Focus();
      //    if(!confirmEmailCode.code2) return setCode2Focus();
      //    if(!confirmEmailCode.code3) return setCode3Focus();
      //    if(!confirmEmailCode.code4) return setCode4Focus();
      //    if(!confirmEmailCode.code5) return setCode5Focus();
      //    if(!confirmEmailCode.code6) return setCode6Focus();
      if (
        confirmEmailCode.code1 &&
        confirmEmailCode.code2 &&
        confirmEmailCode.code3 &&
        confirmEmailCode.code4 &&
        confirmEmailCode.code5 &&
        confirmEmailCode.code6
      ) {
        setConfirmBtnFocus();
      }
    }
  }, [recievedCode, confirmEmailCode]);

  const fullConfirmationCode =
    confirmEmailCode.code1 +
    confirmEmailCode.code2 +
    confirmEmailCode.code3 +
    confirmEmailCode.code4 +
    confirmEmailCode.code5 +
    confirmEmailCode.code6;

  const handleResendCode = () => {
    handleCountForRecaptcha();
    if (attempts >= 2) {
      setProgress(0);
      setProgressLoading(false);
      return;
    }
    resendCode("creatingNewAccount");
  };

  const resendCode = async () => {
    try {
      const res = await checkEmailAlreadyExists();
      if (res === "continue signup") {
        await Auth.signUp({
          username: values.email,
          password: values.password,
          autoSignIn: {
            enabled: true,
          },
        });
      }
      setLoader(false);
      setProgressLoading(false);
      setRecievedCode(true);
      setVerificationCodeSent(true);
      setSignupResendCode(true);
      setOpen(true);
      setUserExists(false);
    } catch (err) { }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") !== null) {
      const QUERY_ADDSIGNUPUSER = {
        query: `query MyQuery {
                getSignupUser (loginCode : "${loginCode}") {
                    accessJobPosting
                    accessResumeDB
                    email
                    hospitalID
                    huID
                   }
                }`,
      };
      gqlquery(QUERY_ADDSIGNUPUSER, null)
        .then((res) => res.json())
        .then((datas) => setEmaiBylLink(datas?.data?.getSignupUser));
      // .finally((e) => setIsLoading(false));
    }
  }, []);

  // email validaton
  const re = /^[a-zA-Z0-9._]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;

  //password validation
  let passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  const handleSubmit = async (event, from) => {
    setOpen(false);
    if (event.key === "Enter" || from === "onClick") {
      handleCountForRecaptcha();
      if (attempts >= 2) {
        setProgress(0);
        setProgressLoading(false);
        return;
      }
      if (emailByLink?.email) {
        __currentUserEmail = emailByLink?.email;
        setUserEmail(emailByLink?.email);
      } else {
        __currentUserEmail = values.email;
        setUserEmail(values.email);
        setEmailCode(__currentUserEmail);
      }

      setError("");
      setStrongPassword("");
      setWeakPassword("");
      let finalpassword;
      if (
        values.email === "" ||
        values.password === "" ||
        values.confirmpassword === "" ||
        re.test(values.email) === false
      ) {
        setError("Email is mandatory!");
        setErrorPassword("Password is mandatory!");
        setErrorConfirmPassword("Confirm Password is mandatory!");
        setErrorInvalidEmail("Email address is invalid.");
        return;
      }

      if (values.password === values.confirmpassword) {
        finalpassword = values.password;
        setMatchPassword("");
      } else {
        setOpen(true);
        setMatchPassword("Confirm password - passwords do not match.");
        return;
      }

      let regex =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      if (regex.test(finalpassword)) {
        setStrongPassword("Password is strong.");
      } else {
        setWeakPassword(
          "Password must be at least 8 characters with at least 1 special character 1 digit and 1 Capital Letter."
        );
        return;
      }

      setLoader(true);
      setProgressLoading(true);
      let userExists;

      if (!userExistsData) {
        userExists = await checkEmailAlreadyExists();
        setUserExistsData(userExists);
        if (userExists === "continue signup") {
          try {
            await Auth.signUp({
              username: __currentUserEmail,
              password: values.password,
              autoSignIn: {
                enabled: true,
              },
            });
            setAttempts((pre) => 0);
            setLoader(false);
            setProgressLoading(false);
            setProgress(0);
            setVerificationCodeSent(true);
            setOpen(true);
            setRecievedCode(true);
          } catch (err) {
            setLoader(false);
            setProgressLoading(false);
            setProgress(0);
            setError(err?.message);
            setOpen(true);
            setUserExistsData('')
          }
        } else {
          setLoader(false);
          setProgressLoading(false);
          setProgress(0);
          setError(userExists);
          setOpen(true);
          setUserExistsData('')
        }
      } else {
        if (
          userExistsData === "continue signup"
        ) {
          try {
            await Auth.signUp({
              username: __currentUserEmail,
              password: values.password,
              autoSignIn: {
                enabled: true,
              },
            });
            setAttempts((pre) => 0);
            setLoader(false);
            setProgressLoading(false);
            setProgress(0);
            setVerificationCodeSent(true);
            setOpen(true);
            setRecievedCode(true);
            setUserExistsData('')
          } catch (err) {
            setLoader(false);
            setProgressLoading(false);
            setProgress(0);
            setError(err?.message);
            setOpen(true);
            setUserExistsData('')
          }
        } else {
          setLoader(false);
          setProgressLoading(false);
          setProgress(0);
          setError(userExistsData);
          setOpen(true);
          setUserExistsData('')
        }
      }
    }
    setErrorPassword("");
    setErrorConfirmPassword("");
    setErrorInvalidEmail("");
    setUserExists(false);
    return;
  };

  const handleConfirmEmailSubmit = async (event, from) => {
    if (event.key === "Enter" || from === "onClick") {
      handleCountForRecaptcha();
      if (attempts >= 2) {
        setProgress(0);
        setProgressLoading(false);
        return;
      }
      if (fullConfirmationCode === "" || fullConfirmationCode === undefined) {
        setError("Verification code can't be empty.");
        return;
      } else {
        setError("");
      }

      if (fullConfirmationCode?.length < 6) {
        setError("Verification Code must be 6 digits long.");
        return;
      } else {
        setError("");
      }

      setLoader(true);
      setProgressLoading(true);
      try {
        if (emailByLink?.email) {
          __currentUserEmail = emailByLink?.email;
          handleChange("email");
        } else {
          __currentUserEmail = emailCode;
          handleChange("email");
        }

        if (__currentUserEmail === "") {
          __currentUserEmail = values.email ? values.email : currentEmail;
        }

        try {
          await Auth.confirmSignUp(__currentUserEmail, fullConfirmationCode);
          const unsubscribe = Hub.listen("auth", ({ payload }) => {
            const { event } = payload;
            if (event === "autoSignIn") {
              const user = payload.data;
              unsubscribe();
              setRegisterSuccess(true);
              var access_token =
                user["signInUserSession"]["accessToken"]["jwtToken"];
              var idToken = user["signInUserSession"]["idToken"]["jwtToken"];
              var refresh_token =
                user["signInUserSession"]["refreshToken"]["token"];
              localStorage.setItem("accessToken", access_token);
              localStorage.setItem("idToken", idToken);
              localStorage.setItem("refreshToken", refresh_token);
              localStorage.setItem("flow", "hospital");
              setAttempts((pre) => 0);
              setisLogin(true);
              //   setLoginSuccess(true);
              setFailureLogin(false);
              setOpen(true);
              setProgress(0);
              setLoader(false);
              setProgressLoading(false);
              deviceTokenVerify();
              navigate("/recruiter-registration", { state: { history: location?.state?.history, quantity: (location?.state?.quantity || 0), price: (location?.state?.price || 0), name: (location?.state?.name || ""), taxRate: 18 } });
            } else if (event === "autoSignIn_failure") {
              unsubscribe();
              navigate("/recruiters-login");
            }
          });
        } catch (error) {
          setProgress(0);
          setLoader(false);
          setProgressLoading(false);
          setOpen(true);
          setError(error.message);
          return;
        }
      } catch (err) {
        setProgress(0);
        setLoader(false);
        setProgressLoading(false);
        setOpen(true);
        setError(err.message);
        return;
      }
      setError("");
    }
  };

  const deviceTokenVerify = async () => {
    const deviceTokenFromLocal = localStorage.getItem("deviceTokenRecruiter");
    if (!deviceTokenFromLocal) {
      const token = await requestForToken();
      const QUERY_ADD_TOKEN = {
        query: `mutation MyMutation {
                updateDeviceTokenForRecruiter(deviceToken:"${token}")
            }`,
        operationName: "MyMutation",
      };
      gqlquery(QUERY_ADD_TOKEN, null)
        .then((res) => res.json())
        .then((data) => {
          localStorage.setItem("deviceTokenRecruiter", token);
        })
        .catch((error) => { });
    }
  };
  const sendEmailToAdmin = (value) => {
    const QUERY_POSTCANDIDATEFORM = {
      query: `mutation MyMutation{
            addRecruiterFormData(
                avgResumesReceivingMax: -1,
                avgResumesReceivingMin: -1,
                costForHiringMax: -1,
                costForHiringMin: -1,
                currentRC: "",
                isRelocationConsiderable: "",
                primaryJobPostions: "",
                recruitmentsCountMax: -1,
                recruitmentsCountMin: -1,
                type: "${value}",
                haveAdditionalBranch: "")
        }`,
      variables: null,
      operationName: "MyMutation",
    };
    try {
      gqlquery(QUERY_POSTCANDIDATEFORM, null)
        .then((res) => res.json())
        .then((datas) => { })
    } catch (error) { }
  }

  const handleLogIn = async (event, from) => {
    if (event.key === "Enter" || from === "onClick") {
      handleCountForRecaptcha();
      if (attempts >= 2) {
        setProgress(0);
        setProgressLoading(false);
        return;
      }
      if (
        values.email === "" ||
        values.password === "" ||
        re.test(values.email) === false
      ) {
        setError("Email is mandatory!");
        setErrorPassword("Password is mandatory!");
        setErrorInvalidEmail("Email address is invalid.");
        return;
      }

      setLoader(true);
      setProgressLoading(true);

      try {
        const user = await Auth.signIn(values.email, values.password);
        var access_token = user["signInUserSession"]["accessToken"]["jwtToken"];
        var idToken = user["signInUserSession"]["idToken"]["jwtToken"];
        var refresh_token = user["signInUserSession"]["refreshToken"]["token"];
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("refreshToken", refresh_token);
        localStorage.setItem("flow", "hospital");
        setAttempts((pre) => 0);
        setisLogin(true);
        setLoginSuccess(true);
        setFailureLogin(false);
        setOpen(true);
        setProgress(0);
        setLoader(false);
        setProgressLoading(false);
        deviceTokenVerify();
      } catch (error) {
        setFailureLogin(true);
        setLoginSuccess(false);
        setError(error.message);
        setProgress(0);
        setLoader(false);
        setProgressLoading(false);
        setOpen(true);
      }

      if (access_token) {
        getUserProfile();
        gqlquery(QUERY_GETHOSPITAL, null)
          .then((res) => res.json())
          .then((datas) => {
            setProgress(0);
            setLoader(false);
            setProgressLoading(false);
            if (datas?.data?.getHospital) {
               if (location?.state?.history === "/post-jobs") {
                sendEmailToAdmin("Post Jobs")
                navigate("/checkout-plan", { state: { history: location?.state?.history, quantity: (location?.state?.quantity || 0), price: (location?.state?.price || 0), name: (location?.state?.name || ""), taxRate: 18 } });
              } else if (location?.state?.history === "/search-candidate") {
                sendEmailToAdmin("Search Candidate")
                navigate("/search-candidate-enquiry", { state: { history: location?.state?.history } });
              } else if (location?.state?.history === "/staffing-solutions") {
                sendEmailToAdmin("Staffing Solutions")
                navigate("/staffing-solutions-enquiry", { state: { history: location?.state?.history } });
              } else if (location?.state?.history === "/branding-solutions") {
                sendEmailToAdmin("Branding Solutions")
                navigate("/branding-solutions-enquiry", { state: { history: location?.state?.history } });
              } else if (location?.state?.from === "applicantDetailsRF") {
                navigate(location?.state?.history);
              } else if(NavigateHistory[0]){
                  navigate(NavigateHistory[0])
               }else {
                navigate('/hospital-dashboard')
               }
            } else {
              navigate("/recruiter-registration");
            }
          });
      }
      setError("");
      setErrorPassword("");
      setErrorInvalidEmail("");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpen1(false);
    setSignupResendCode(false);
    setError("");
    setMatchPassword("");
    setSuccess("");
    setFailure(false);
    setUserExists(false);
    setFailureLogin(false);
    setLoginSuccess(false);
    setRegisterSuccess(false);
    setVerificationCodeSent(false);
  };

  useEffect(() => {
    let timer;
    const progressFunc = () => {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 5
        );
      }, 182.7);
    };

    if (progressLoading) {
      progressFunc();
    }

    return () => {
      clearInterval(timer);
    };
  }, [progressLoading]);

  // open forgot password
  const handleOpenForgotPassword = () => {
    if (values.email === "") {
      setError("Email is mandatory!");
    } else if (re.test(values.email) === false) {
      setErrorInvalidEmail("Email address is invalid.");
    }
    else {
      navigate("/forgot-password", { state: { values, isDoctor: false } });
    }
  };
  const handleForgotPasswordVerificationCode = async (event, from) => {
    if (event.key === "Enter" || from === "onClick") {
      if (
        values.email === "" ||
        values.forgotPasswordCode === "" ||
        re.test(values.email) === false
      ) {
        setError("Email is mandatory!");
        setErrorPassword("Reset code is mandatory!");
        setErrorInvalidEmail("Email address is invalid.");
        return;
      }
      navigate("/create-new-password", {
        state: {
          forgotPasswordCode: values.forgotPasswordCode,
          email: values.email || location.state?.values.email,
          isDoctor: false,
        },
      });
      setError("");
      setErrorPassword("");
      setErrorInvalidEmail("");
    }
  };

  const recoveryEmail = sessionStorage.getItem("recoveryEmail");
  const currentEmail = sessionStorage.getItem("email");
  const resetPassword = sessionStorage.getItem("finalpassword");

  const handleVerificationCode = async (event, from) => {
    if (event.key === "Enter" || from === "onClick") {
      handleCountForRecaptcha();
      if (attempts >= 2) {
        setProgress(0);
        setProgressLoading(false);
        return;
      }
      if (values.email === "") {
        values.email = location.state?.values?.email;
      }

      if (recoveryEmail) {
        values.email = recoveryEmail;
      }
      if (values.email === "") {
        setError("Email is mandatory!");
        return;
      }

      setLoader(true);
      setProgressLoading(true);

      try {
        const response = await checkEmailAlreadyExists(values.email)
        if (response === "continue signup") {
          setLoader(false);
          setProgressLoading(false);
          setProgress(0);
          setFailure(true);
          setOpen(true);
          setError("This email address is not registered!");
          return
        } else {
          const res = await Auth.forgotPassword(values.email);
          setAttempts((pre) => 0);
          setLoader(false);
          setProgressLoading(false);
          setProgress(0);
          setSuccess("A code has been sent to your email address!");
          setOpen(true);
          setError("");
          setForgotPassword("true");
        }
      } catch (error) {
        setLoader(false);
        setProgressLoading(false);
        setProgress(0);
        setFailure(true);
        setOpen(true);
        setError("Something went wrong. Please try again.");
        return;
      }


      setError("");
      setErrorInvalidEmail("");
    }
  };
  const hanldeResetPassword = async (event, from) => {
    if (event.key === "Enter" || from === "onClick") {
      handleCountForRecaptcha();
      if (attempts >= 2) {
        setProgress(0);
        setProgressLoading(false);
        return;
      }
      if (values.email === "") {
        values.email =
          values.email ||
          location.state?.values?.email ||
          recoveryEmail ||
          currentEmail;
      }

      let finalpassword;
      if (resetPassword) {
        values.password = resetPassword;
        values.confirmpassword = resetPassword;
      }
      if (values.password === values.confirmpassword) {
        finalpassword = values.password;
        setMatchPassword("");
      } else {
        setOpen(true);
        setMatchPassword("Confirm password - passwords do not match.");
        return;
      }

      if (values.password === "" || values.confirmpassword === "") {
        setErrorPassword("New Password is mandatory!");
        setErrorConfirmPassword("New Confirm Password is mandatory!");
        return;
      }

      let regex =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      if (regex.test(values.password) && regex.test(values.password)) {
        setStrongPassword("Password is strong.");
      } else {
        setWeakPassword(
          "Password must be at least 8 characters with at least 1 special character 1 digit and 1 Capital Letter."
        );
        return;
      }

      setLoader(true);
      setProgressLoading(true);

      try {
        await Auth.forgotPasswordSubmit(
          values.email,
          values.forgotPasswordCode,
          finalpassword
        );
        setOpen(true);
        setSuccess("Your password has been updated successfully!");
        setSuccessForgetPassword(
          "Your password has been updated successfully!"
        );
        // values.password = "";
        // values.confirmpassword = "";
        // values.forgotPasswordCode = "";
        setError("");
        setErrorPassword("");
        setErrorConfirmPassword("");
        sessionStorage.clear();
        setTimeout(() => navigate("/recruiters-login"), 3000);
        setLoader(false);
        setProgressLoading(false);
        setProgress(0);

      } catch (err) {
        setLoader(false);
        setProgressLoading(false);
        setProgress(0);
        setOpen(true);
        setErrorPassword("");
        setErrorConfirmPassword("");
        sessionStorage.clear();
        setError(err.message);
        setTimeout(() => navigate("/forgot-password"), 1000);
        return;
      }
      setError("");
      setErrorPassword("");
      setErrorConfirmPassword("");
    }
  };

  const handleFocusEmail = async (params) => {
    if (params === "signup") {
      const res = await checkEmailAlreadyExists();
      setUserExistsData(res);
    }
    setFocusedEmail(true);
  };

  const handlePasswordFocus = (e) => {
    setFocusedPassword(true);
  };

  const handleConfirmPasswordFocus = (e) => {
    setFocusedConfirmPassword(true);
  };

  const handleFocusSignUpVerificationCode = (e) => {
    setFocusedSignUpVerificationCode(true);
  };

  const handleFocusForgotPasswordVerificationCode = (e) => {
    setFocusedForgotPasswordVerificationCode(true);
  };

  const handleFocusForgetPassword = (e) => {
    setFocusedForgetPassword(true);
  };

  const handleFocusForgetConfirmPassword = (e) => {
    setFocusedForgetConfirmPassword(true);
  };

  const handleSignUpNavigate = () => {
    navigate("/recruiters-signup", { state: { history: location?.state?.history, quantity: (location?.state?.quantity || 0), price: (location?.state?.price || 0), name: (location?.state?.name || ""), taxRate: 18 } });
  };

  const handleLoginNavigate = () => {
    navigate("/recruiters-login", { state: { history: location?.state?.history, quantity: (location?.state?.quantity || 0), price: (location?.state?.price || 0), name: (location?.state?.name || ""), taxRate: 18 } });
  };

  return (
    <>
      {
        (!token || isLogin) ? <SignupGrid style={{ padding: !matches && props.source === "recruiterhome" ? "0px 39px 40px" : !matches ? "20px 39px 40px" : "20px 26px 40px", }} item className="doctor-signup">
          {recievedCode ? (
            <Box sx={{
              height: props.source !== "recruiterhome" ? "550px" : "370px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              pt: props.source !== "recruiterhome" ? 8 : 2,
            }}>
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: !matches ? 2.5 : 0, mt: !matches ? 0 : -8, mb: !matches ? 0 : 6 }}>
                {props.source !== "recruiterhome" && (!matches ? <img src={ChevronLeftIconsvg} onClick={() => navigate(-1)} style={{ cursor: "pointer" }} alt="ChevronLeftIconsvg" /> : (
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIosIcon
                      fontSize="small"
                      sx={{ color: "var(--clr-btn-primary)" }}
                    />
                  </IconButton>
                ))}
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontSize: !matches ? "24px" : "18px", fontWeight: "600", color: "var(--clr-blue-footer)", textAlign: "center", mt: !matches ? 0 : 0, mb: !matches ? 0 : 0, ml: !matches ? "" : "0px" }}
                >
                  Verify Email
                </Typography>
              </Box>
              <Box sx={{ mt: !matches ? 0 : -4, mx: "auto" }}>
                <InputLabel sx={{ py: 0.5 }}>
                  <Typography sx={{ fontSize: 15, fontWeight: 400 }}>
                    A six digit OTP has been sent to
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "", alignItems: "center", gap: 1.5 }}>
                    <Typography sx={{ color: "#395987", fontWeight: 600, textDecoration: "underline" }}>
                      {userEmail}
                    </Typography>
                    <ModeEditIcon onClick={() => setRecievedCode(null)} sx={{ fontSize: 15, color: "#395987", cursor: "pointer" }} />
                  </Box>
                </InputLabel>
                <Box sx={{ display: "flex", textAlign: "center", gap: 1, mt: 3 }}>
                  <TextField
                    sx={{ width: 50, display: "inline-block", borderRadius: 1 }}
                    variant="outlined"
                    disableUnderline
                    InputProps={{
                      sx: {
                        ".MuiOutlinedInput-input": {
                          padding: '10.5px 14px',
                          textAlign: "center",
                        },
                      }
                    }}
                    onChange={handleConfirmationCode("code1")}
                    onKeyDown={focusChangeKeyDown("code1")}
                    value={confirmEmailCode.code1}
                    inputRef={code1Ref}
                    onInput={(e) => onlyNumbers(e)}
                    inputProps={{ inputMode: 'numeric' }}
                    onBlur={handleFocusSignUpVerificationCode}
                    size="small"
                    type="text"
                    inputMode="text"
                    autoComplete="off"
                  />
                  <TextField
                    sx={{ width: 50, display: "inline-block", borderRadius: 1 }}
                    variant="outlined"
                    disableUnderline
                    InputProps={{
                      sx: {
                        ".MuiOutlinedInput-input": {
                          padding: '10.5px 14px',
                          textAlign: "center",
                        },
                      }
                    }}
                    onInput={(e) => onlyNumbers(e)}
                    onChange={handleConfirmationCode("code2")}
                    onKeyDown={focusChangeKeyDown("code2")}
                    value={confirmEmailCode.code2}
                    inputRef={code2Ref}
                    inputProps={{ inputMode: 'numeric' }}
                    onBlur={handleFocusSignUpVerificationCode}
                    size="small"
                    type="text"
                    inputMode="text"
                    autoComplete="off"
                  />
                  <TextField
                    sx={{ width: 50, display: "inline-block", borderRadius: 1 }}
                    variant="outlined"
                    disableUnderline
                    InputProps={{
                      sx: {
                        ".MuiOutlinedInput-input": {
                          padding: '10.5px 14px',
                          textAlign: "center",
                        },
                      }
                    }}
                    onInput={(e) => onlyNumbers(e)}
                    onChange={handleConfirmationCode("code3")}
                    onKeyDown={focusChangeKeyDown("code3")}
                    value={confirmEmailCode.code3}
                    inputRef={code3Ref}
                    inputProps={{ inputMode: 'numeric' }}
                    onBlur={handleFocusSignUpVerificationCode}
                    size="small"
                    type="text"
                    inputMode="text"
                    autoComplete="off"
                  />
                  <TextField
                    sx={{ width: 50, display: "inline-block", borderRadius: 1 }}
                    variant="outlined"
                    disableUnderline
                    InputProps={{
                      sx: {
                        ".MuiOutlinedInput-input": {
                          padding: '10.5px 14px',
                          textAlign: "center",
                        },
                      }
                    }}
                    onInput={(e) => onlyNumbers(e)}
                    onChange={handleConfirmationCode("code4")}
                    onKeyDown={focusChangeKeyDown("code4")}
                    value={confirmEmailCode.code4}
                    inputRef={code4Ref}
                    inputProps={{ inputMode: 'numeric' }}
                    onBlur={handleFocusSignUpVerificationCode}
                    size="small"
                    type="text"
                    inputMode="text"
                    autoComplete="off"
                  />
                  <TextField
                    sx={{ width: 50, display: "inline-block", borderRadius: 1 }}
                    variant="outlined"
                    disableUnderline
                    InputProps={{
                      sx: {
                        ".MuiOutlinedInput-input": {
                          padding: '10.5px 14px',
                          textAlign: "center",
                        },
                      }
                    }}
                    onInput={(e) => onlyNumbers(e)}
                    onChange={handleConfirmationCode("code5")}
                    onKeyDown={focusChangeKeyDown("code5")}
                    value={confirmEmailCode.code5}
                    inputRef={code5Ref}
                    inputProps={{ inputMode: 'numeric' }}
                    onBlur={handleFocusSignUpVerificationCode}
                    size="small"
                    type="text"
                    inputMode="text"
                    autoComplete="off"
                  />
                  <TextField
                    sx={{ width: 50, display: "inline-block", borderRadius: 1, }}
                    variant="outlined"
                    disableUnderline
                    InputProps={{
                      sx: {
                        ".MuiOutlinedInput-input": {
                          padding: '10.5px 14px',
                          textAlign: "center",
                        },
                      }
                    }}
                    onInput={(e) => onlyNumbers(e)}
                    onChange={handleConfirmationCode("code6")}
                    onKeyDown={focusChangeKeyDown("code6")}
                    value={confirmEmailCode.code6}
                    inputRef={code6Ref}
                    inputProps={{ inputMode: 'numeric' }}
                    onBlur={handleFocusSignUpVerificationCode}
                    size="small"
                    type="text"
                    inputMode="text"
                    autoComplete="off"
                  />
                </Box>
              </Box>
              {((fullConfirmationCode === "" || fullConfirmationCode === undefined)) && (
                <FormHelperText sx={{ color: "red", mt: -2.5 }}>
                  {error}
                </FormHelperText>
              )}
              {(((fullConfirmationCode !== "") && (fullConfirmationCode?.length < 6))) && (
                <FormHelperText sx={{ color: "red", mt: -2.5 }}>
                  {error}
                </FormHelperText>
              )}
              {((fullConfirmationCode === "" || fullConfirmationCode === undefined || confirmEmailCode?.code?.length < 6) && (focusedSignUpVerificationCode !== true)) && (
                <FormHelperText sx={{ color: "red", mt: -2.5 }}>
                  {error}
                </FormHelperText>
              )}
              {(sessionStorage.getItem("comingFrom") === "fromForgotPassword") && (
                <FormHelperText sx={{ color: "red", mt: -2.5 }}>
                  ** Please verify your email to reset the password.
                </FormHelperText>
              )}
              {error && (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error">
                    Invalid verification code provided, please try again.
                  </Alert>
                </Snackbar>
              )}
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignContent: "space-between", flexGrow: !matches ? 0 : 1, }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 0.5 }}>
                  <Typography sx={{ fontSize: 15, color: "#6F7482", fontWeight: 400 }}>
                    Didn't receive code?
                  </Typography>
                  <Typography disabled={showRecaptcha} onClick={handleResendCode} sx={{ fontSize: 16, color: "#395987", fontWeight: 600, textDecoration: "underline", cursor: "pointer", opacity: showRecaptcha && 0.3 }}>
                    Request Again
                  </Typography>
                </Box>
                {showRecaptcha && (
                  <Box sx={{ mt: 1.5 }}>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={(token) => handleRecaptchaChange(token)} />
                  </Box>
                )}
                <Button
                  disabled={showRecaptcha}
                  variant="contained"
                  type="submit"
                  ref={confirmBtnFocus}
                  onClick={(event) => handleConfirmEmailSubmit(event, "onClick")}
                  sx={{ p: 1, mt: 2, borderRadius: 16, opacity: showRecaptcha && 0.3, width: 350, m: "auto" }}
                >
                  {loader ? (
                    <CircularProgress size={25} sx={{ color: "white" }} variant="determinate" value={progress} />
                  ) : (
                    "Confirm Email"
                  )}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                height: props.source !== "recruiterhome" ? "600px" : '385px',
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2.5,
                  }}
                >
                  {props.source !== "recruiterhome" && (!matches ? (
                    <img
                      src={ChevronLeftIconsvg}
                      onClick={() => navigate(-1)}
                      style={{ cursor: "pointer" }}
                      alt="ChevronLeftIconsvg"
                    />
                  ) : (
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBackIosIcon
                        fontSize="small"
                        sx={{ color: "var(--clr-btn-primary)" }}
                      />
                    </IconButton>
                  ))}
                  {props.source !== "recruiterhome" && <Typography
                    variant="h1"
                    sx={{
                      fontSize: !matches ? "24px" : "18px",
                      fontWeight: 700,
                      color: "var(--clr-blue-footer)",
                      textAlign: !matches ? "center" : "start",
                      ml: !matches ? "" : "-10px",
                    }}
                  >
                    {props.pageType === "HospitalSignUp" &&
                      "Create Account"}
                    {props.pageType === "HospitalLogIn" &&
                      "Login as Recruiter"}
                    {props.pageType === "Create New Password" &&
                      "Create New Password"}
                    {props.pageType === "Forgot Password" &&
                      "Forgot Password"}
                  </Typography>}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2.5,
                    borderRadius: "6px",
                    mt: !matches ? 5 : 2.5,
                  }}
                >
                  {props.pageType === "Create New Password" || (
                    <>
                      {recoveryEmail && resetPassword ? (
                        <Box>
                          <InputLabel sx={{ py: 0.5 }}>
                            Email ID <span style={{ color: "red" }}>*</span>{" "}
                          </InputLabel>
                          <TextField
                            sx={{
                              borderRadius: 1,
                              color: "var(--clr-blue-footer)",
                              fontSize: "14px",
                            }}
                            InputProps={{
                              sx: {
                                ".MuiOutlinedInput-input": {
                                  padding: "10.5px 14px",
                                }
                              },
                            }}
                            size="small"
                            disableUnderline
                            id="outlined-adornment-password"
                            defaultValue={
                              recoveryEmail ? recoveryEmail : values.email
                            }
                            type="email"
                            inputMode="email"
                            autoComplete="off"
                            // value={emailByLink?.email}
                            disabled
                            fullWidth
                            onChange={handleChange("email")}
                            placeholder="Email"
                          />
                        </Box>
                      ) : (
                        <Box>
                          <InputLabel sx={{ py: 0.5 }}>
                            Email ID<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            sx={{
                              borderRadius: 1,
                              color: "var(--clr-blue-footer)",
                              fontSize: "14px",
                            }}
                            InputProps={{
                              sx: {
                                ".MuiOutlinedInput-input": {
                                  // border: "1px solid var(--clr-blue-light)",
                                  padding: "10.5px 14px",
                                }
                              },
                            }}
                            size="small"
                            disableUnderline
                            id="outlined-adornment-password"
                            type="email"
                            inputMode="email"
                            autoComplete="off"
                            // value={values.email}
                            disabled={props.pageType === "Forgot Password"}
                            defaultValue={
                              location.state?.values?.email ||
                              values.email ||
                              (props.pageType !== "Register" &&
                                props.pageType !== "Login"
                                ? currentEmail
                                : "")
                            }
                            error={values.email === "" && error}
                            onChange={handleChange("email")}
                            onKeyDown={
                              (props.pageType === "HospitalSignUp" &&
                                handleSubmit) ||
                              (props.pageType === "HospitalLogIn" &&
                                handleLogIn) ||
                              (props.pageType === "Forgot Password" &&
                                forgotPassword !== "true" &&
                                handleVerificationCode) ||
                              (forgotPassword === "true" &&
                                handleForgotPasswordVerificationCode)
                            }
                            placeholder="Email"
                            fullWidth
                            onBlur={() => {
                              (props.pageType === "HospitalSignUp" &&
                                handleFocusEmail("signup")) ||
                                (props.pageType === "HospitalLogIn" &&
                                  handleFocusEmail("login")) ||
                                (props.pageType === "Forgot Password" &&
                                  handleFocusEmail("forgotPassword"))
                            }}
                          />
                          {values.email === "" && focusedEmail === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Email is mandatory!
                            </FormHelperText>
                          )}
                          {values.email === "" && focusedEmail !== true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {error}
                            </FormHelperText>
                          )}
                          {re.test(values.email) === false &&
                            values.email !== "" &&
                            focusedEmail === true && (
                              <FormHelperText sx={{ color: "red", mt: 0 }}>
                                Enter a valid Email Address.
                              </FormHelperText>
                            )}
                          {re.test(values.email) === false &&
                            values.email !== "" &&
                            focusedEmail !== true && (
                              <FormHelperText sx={{ color: "red", mt: 0 }}>
                                {errorInvalidEmail}
                              </FormHelperText>
                            )}
                        </Box>
                      )}
                    </>
                  )}

                  {props.pageType === "Forgot Password" || (
                    <Box>
                      <InputLabel sx={{ py: 0.5 }}>
                        {props.pageType === "Create New Password"
                          ? "New Password"
                          : "Password"}
                        <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <TextField
                        sx={{
                          borderRadius: 1,
                          color: "var(--clr-blue-footer)",
                          fontSize: "14px",
                        }}
                        InputProps={{
                          sx: {
                            ".MuiOutlinedInput-input": {
                              padding: "10.5px 14px",
                            }
                          },
                          endAdornment: (
                            <InputAdornment sx={{ mr: 0.5 }} position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <VisibilityOff
                                    sx={{ color: "var(--clr-blue-footer)" }}
                                  />
                                ) : (
                                  <Visibility
                                    sx={{ color: "var(--clr-blue-footer)" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                        disableUnderline
                        fullWidth
                        error={values.password === "" && errorPassword}
                        defaultValue={
                          resetPassword ? resetPassword : values.password
                        }
                        onChange={handleChange("password")}
                        onKeyDown={
                          (props.pageType === "HospitalSignUp" &&
                            handleSubmit) ||
                          (props.pageType === "HospitalLogIn" && handleLogIn) ||
                          (props.pageType === "Create New Password" &&
                            hanldeResetPassword)
                        }
                        placeholder="Password"
                        type={values.showPassword ? "text" : "password"}
                        onBlur={handlePasswordFocus}
                      />
                      {values.password === "" && focusedPassword === true && (
                        <FormHelperText sx={{ color: "red", mt: 0 }}>
                          Password is mandatory!
                        </FormHelperText>
                      )}
                      {values.password === "" && focusedPassword !== true && (
                        <FormHelperText sx={{ color: "red", mt: 0 }}>
                          {errorPassword}
                        </FormHelperText>
                      )}
                      {props.pageType !== "HospitalLogIn" &&
                        passwordRegex.test(values.password) === true && (
                          <FormHelperText sx={{ color: "green", mt: 0 }}>
                            Password is strong.
                          </FormHelperText>
                        )}
                      {props.pageType !== "HospitalLogIn" &&
                        values.password !== "" &&
                        focusedPassword === true &&
                        passwordRegex.test(values.password) === false && (
                          <FormHelperText sx={{ color: "red", mt: 0 }}>
                            Password must be at least 8 characters with at least
                            1 special character 1 digit and 1 Capital Letter.
                          </FormHelperText>
                        )}

                      {props.pageType === "HospitalLogIn" && (
                        <Box
                          sx={{
                            textAlign: "right",
                            textDecoration: "underline",
                            my: 1,
                          }}
                        >
                          <Button variant="text">
                            <Typography
                              sx={{
                                color: "var(--clr-blue-footer)",
                                fontWeight: 600,
                              }}
                              variant="subtitle2"
                              onClick={handleOpenForgotPassword}
                            >
                              Forgot Password?
                            </Typography>
                          </Button>
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* 6 digits Code */}
                  {forgotPassword && (
                    <Box>
                      <Box>
                        {/* <InputLabel sx={{ py: 0.5 }}>Email ID</InputLabel> */}
                        <InputLabel sx={{ py: 0.5 }}>
                          6 Digits Verification Code
                        </InputLabel>
                        <TextField
                          sx={{
                            borderRadius: 1,
                            color: "var(--clr-blue-footer)",
                            fontSize: "14px",
                          }}
                          InputProps={{
                            sx: {
                              ".MuiOutlinedInput-input": {
                                border: "1px solid var(--clr-blue-light)",
                                padding: "10.5px 14px",
                              }
                            },
                          }}
                          size="small"
                          disableUnderline
                          error={
                            values.forgotPasswordCode === "" && errorPassword
                          }
                          fullWidth
                          type="text"
                          inputMode="text"
                          autoComplete="off"
                          value={values.forgotPasswordCode}
                          onChange={handleChange("forgotPasswordCode")}
                          onKeyDown={
                            forgotPassword === "true" &&
                            handleForgotPasswordVerificationCode
                          }
                          placeholder="Enter 6 Digit Verification Code"
                          inputProps={{ maxLength: 6 }}
                          onInput={(e) => onlyNumbers(e)}
                          onBlur={handleFocusForgotPasswordVerificationCode}
                        />
                        {values.forgotPasswordCode === "" &&
                          focusedForgotPasswordVerificationCode === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Reset code is mandatory!
                            </FormHelperText>
                          )}
                        {values.forgotPasswordCode === "" &&
                          focusedForgotPasswordVerificationCode !== true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {errorPassword}
                            </FormHelperText>
                          )}
                        {values.forgotPasswordCode !== "" &&
                          (values.forgotPasswordCode === undefined ||
                            values?.forgotPasswordCode?.length < 6) &&
                          focusedForgotPasswordVerificationCode === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {/* {errorReset} */}
                              Verification Code must be 6 digits long.
                            </FormHelperText>
                          )}
                        <Box
                          sx={{
                            textAlign: "right",
                            textDecoration: "underline",
                            my: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--clr-blue-footer)",
                              fontWeight: 600,
                            }}
                            variant="subtitle2"
                            component={Link}
                            to="/forgot-password"
                            onClick={() => setForgotPassword("")}
                          >
                            Resend Code
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <InputLabel sx={{ py: 0.5 }}>
                          New Password<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          sx={{
                            borderRadius: 1,
                            color: "var(--clr-blue-footer)",
                            fontSize: "14px",
                          }}
                          InputProps={{
                            sx: {
                              ".MuiOutlinedInput-input": {
                                // border: "1px solid var(--clr-blue-light)",
                                padding: "10.5px 14px",
                              }
                            },
                            endAdornment: (
                              <InputAdornment sx={{ mr: 0.5 }} position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <VisibilityOff
                                      sx={{ color: "var(--clr-blue-footer)" }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{ color: "var(--clr-blue-footer)" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          disableUnderline
                          fullWidth
                          error={values.awardname === "" && errorPassword}
                          defaultValue={
                            resetPassword ? resetPassword : values.password
                          }
                          onChange={handleChange("password")}
                          onKeyDown={
                            (props.pageType === "HospitalSignUp" &&
                              handleSubmit) ||
                            (props.pageType === "HospitalLogIn" &&
                              handleLogIn) ||
                            (props.pageType === "Create New Password" &&
                              hanldeResetPassword)
                          }
                          placeholder="Password"
                          type={values.showPassword ? "text" : "password"}
                          onBlur={handleFocusForgetPassword}
                        />
                        {values.password === "" &&
                          focusedForgetPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Password is mandatory!
                            </FormHelperText>
                          )}
                        {values.password === "" &&
                          focusedForgetPassword !== true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {errorPassword}
                            </FormHelperText>
                          )}
                        {passwordRegex.test(values.password) === true && (
                          <FormHelperText sx={{ color: "green", mt: 0 }}>
                            Password is strong.
                          </FormHelperText>
                        )}
                        {values.password !== "" &&
                          passwordRegex.test(values.password) === false &&
                          focusedForgetPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Password must be at least 8 characters with at
                              least 1 special character 1 digit and 1 Capital
                              Letter.
                            </FormHelperText>
                          )}

                        {props.pageType === "HospitalLogIn" && (
                          <Box
                            sx={{
                              textAlign: "right",
                              textDecoration: "underline",
                              my: 1,
                            }}
                          >
                            <Button variant="text">
                              <Typography
                                sx={{
                                  color: "var(--clr-blue-footer)",
                                  fontWeight: 600,
                                }}
                                variant="subtitle2"
                                onClick={handleOpenForgotPassword}
                              >
                                Forgot Password?
                              </Typography>
                            </Button>
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ my: 1.3 }}>
                        <InputLabel sx={{ py: 0.5 }}>
                          Confirm New Password
                          <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          sx={{ borderRadius: 1 }}
                          InputProps={{
                            sx: {
                              ".MuiOutlinedInput-input": {
                                // border: "1px solid var(--clr-blue-light)",
                                padding: "10.5px 14px",
                              }
                            },
                            endAdornment: (
                              <InputAdornment sx={{ mr: 0.5 }} position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showConfirmPassword ? (
                                    <VisibilityOff
                                      sx={{ color: "var(--clr-blue-footer)" }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{ color: "var(--clr-blue-footer)" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          disableUnderline
                          fullWidth
                          defaultValue={
                            resetPassword
                              ? resetPassword
                              : values.confirmpassword
                          }
                          error={
                            values.confirmpassword === "" &&
                            errorConfirmPassword
                          }
                          onChange={handleChange("confirmpassword")}
                          onKeyDown={
                            (props.pageType === "HospitalSignUp" &&
                              handleSubmit) ||
                            (props.pageType === "Create New Password" &&
                              hanldeResetPassword)
                          }
                          placeholder="Confirm Password"
                          type={
                            values.showConfirmPassword ? "text" : "password"
                          }
                          endAdornment={
                            <InputAdornment sx={{ mr: 0.5 }} position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showConfirmPassword ? (
                                  <VisibilityOff
                                    sx={{ color: "var(--clr-blue-footer)" }}
                                  />
                                ) : (
                                  <Visibility
                                    sx={{ color: "var(--clr-blue-footer)" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          onBlur={handleFocusForgetConfirmPassword}
                        />
                        {values.confirmpassword === "" &&
                          focusedForgetConfirmPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Confirm Password is mandatory!
                            </FormHelperText>
                          )}
                        {values.confirmpassword === "" &&
                          focusedForgetConfirmPassword !== true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {errorConfirmPassword}
                            </FormHelperText>
                          )}
                        {values.confirmpassword !== "" &&
                          values.confirmpassword !== "" &&
                          values.password !== values.confirmpassword &&
                          focusedForgetConfirmPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Password & Confirm password didn't match
                            </FormHelperText>
                          )}
                        {passwordRegex.test(values.confirmpassword) ===
                          true && (
                            <FormHelperText sx={{ color: "green", mt: 0 }}>
                              {values.confirmpassword !== "" &&
                                values.confirmpassword !== "" &&
                                values.password !== values.confirmpassword
                                ? ""
                                : "Password is strong."}
                            </FormHelperText>
                          )}
                        {values.confirmpassword !== "" &&
                          passwordRegex.test(values.confirmpassword) ===
                          false &&
                          focusedForgetConfirmPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {values.confirmpassword !== "" &&
                                values.confirmpassword !== "" &&
                                values.password !== values.confirmpassword &&
                                focusedForgetConfirmPassword === true
                                ? ""
                                : "Password must be at least 8 characters with at least 1 special character 1 digit and 1 Capital Letter."}
                            </FormHelperText>
                          )}
                      </Box>
                    </Box>
                  )}
                  {props.pageType === "HospitalLogIn" ||
                    props.pageType === "Forgot Password" || (
                      <Box>
                        <InputLabel sx={{ py: 0.5 }}>
                          Confirm Password
                          <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          sx={{ borderRadius: 1, fontSize: "14px" }}
                          InputProps={{
                            sx: {
                              ".MuiOutlinedInput-input": {
                                padding: "10.5px 14px",
                              }
                            },
                            endAdornment: (
                              <InputAdornment sx={{ mr: 0.5 }} position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showConfirmPassword ? (
                                    <VisibilityOff
                                      sx={{ color: "var(--clr-blue-footer)" }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{ color: "var(--clr-blue-footer)" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          disableUnderline
                          fullWidth
                          defaultValue={
                            resetPassword
                              ? resetPassword
                              : values.confirmpassword
                          }
                          error={
                            values.confirmpassword === "" &&
                            errorConfirmPassword
                          }
                          onChange={handleChange("confirmpassword")}
                          onKeyDown={
                            (props.pageType === "HospitalSignUp" &&
                              handleSubmit) ||
                            (props.pageType === "Create New Password" &&
                              hanldeResetPassword)
                          }
                          placeholder="Confirm Password"
                          type={
                            values.showConfirmPassword ? "text" : "password"
                          }
                          endAdornment={
                            <InputAdornment sx={{ mr: 0.5 }} position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showConfirmPassword ? (
                                  <VisibilityOff
                                    sx={{ color: "var(--clr-blue-footer)" }}
                                  />
                                ) : (
                                  <Visibility
                                    sx={{ color: "var(--clr-blue-footer)" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          onBlur={handleConfirmPasswordFocus}
                        />
                        {values.confirmpassword === "" &&
                          focusedConfirmPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Confirm Password is mandatory!
                            </FormHelperText>
                          )}
                        {values.confirmpassword === "" &&
                          focusedConfirmPassword !== true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {errorConfirmPassword}
                            </FormHelperText>
                          )}
                        {values.confirmpassword !== "" &&
                          values.confirmpassword !== "" &&
                          values.password !== values.confirmpassword &&
                          focusedConfirmPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              Password & Confirm password didn't match
                            </FormHelperText>
                          )}
                        {passwordRegex.test(values.confirmpassword) ===
                          true && (
                            <FormHelperText sx={{ color: "green", mt: 0 }}>
                              {values.confirmpassword !== "" &&
                                values.confirmpassword !== "" &&
                                values.password !== values.confirmpassword
                                ? ""
                                : "Password is strong."}
                            </FormHelperText>
                          )}
                        {values.confirmpassword !== "" &&
                          passwordRegex.test(values.confirmpassword) ===
                          false &&
                          focusedConfirmPassword === true && (
                            <FormHelperText sx={{ color: "red", mt: 0 }}>
                              {values.confirmpassword !== "" &&
                                values.confirmpassword !== "" &&
                                focusedConfirmPassword === true &&
                                values.password !== values.confirmpassword
                                ? ""
                                : "Password must be at least 8 characters with at least 1 special character 1 digit and 1 Capital Letter."}
                            </FormHelperText>
                          )}
                      </Box>
                    )}
                  {error && (
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {error}
                      </Alert>
                    </Snackbar>
                  )}
                  {values.password === values.confirmpassword && (
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {error}
                      </Alert>
                    </Snackbar>
                  )}
                  {matchPassword && (
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {matchPassword}
                      </Alert>
                    </Snackbar>
                  )}
                  {showRecaptcha && (
                    <Box sx={{ mb: -1.5 }}>
                      <ReCAPTCHA
                        sitekey={
                          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
                        }
                        onChange={(token) => handleRecaptchaChange(token)}
                      />
                    </Box>
                  )}
                  {props.pageType === "HospitalLogIn" && (
                    <Button
                      disabled={showRecaptcha}
                      variant="contained"
                      type="submit"
                      onClick={(event) => handleLogIn(event, "onClick")}
                      sx={{
                        p: 1,
                        my: 2,
                        borderRadius: 16,
                        opacity: showRecaptcha && 0.3,
                        width:  matches ? "-webkit-fill-available":350,
                        m: "auto",
                      }}
                    >
                      {loader ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "white" }}
                          variant="determinate"
                          value={progress}
                        />
                      ) : (
                        "Log In"
                      )}
                    </Button>
                  )}
                  {props.pageType === "HospitalSignUp" && (
                    <Button
                      disabled={showRecaptcha}
                      variant="contained"
                      type="submit"
                      onClick={(event) => handleSubmit(event, "onClick")}
                      sx={{
                        p: 1,
                        my: 3,
                        borderRadius: 16,
                        opacity: showRecaptcha && 0.3,
                        width: matches ? "-webkit-fill-available":350,
                        m: "auto",
                      }}
                    >
                      {loader ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "white" }}
                          variant="determinate"
                          value={progress}
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </Button>
                  )}

                  {props.pageType === "Forgot Password" &&
                    forgotPassword === "" && (
                      <Button
                        disabled={showRecaptcha}
                        variant="contained"
                        type="submit"
                        onClick={(event) =>
                          handleVerificationCode(event, "onClick")
                        }
                        sx={{
                          borderRadius: 16,
                          mt: 4,
                          opacity: showRecaptcha && 0.3,
                          width: 350,
                          m: "auto",
                        }}
                      >
                        {loader ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "white" }}
                            variant="determinate"
                            value={progress}
                          />
                        ) : (
                          "Get Code"
                        )}
                      </Button>
                    )}
                  {forgotPassword === "true" && (
                    <Button
                      disabled={showRecaptcha}
                      variant="contained"
                      type="submit"
                      sx={{ borderRadius: 16, opacity: showRecaptcha && 0.3 }}
                      onClick={(event) => hanldeResetPassword(event, "onClick")}
                    >
                      {loader ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "white" }}
                          variant="determinate"
                          value={progress}
                        />
                      ) : (
                        "Reset Password"
                      )}
                    </Button>
                  )}
                  {props.pageType === "Create New Password" && (
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={(event) => hanldeResetPassword(event, "onClick")}
                      sx={{ borderRadius: 16, mt: 2 }}
                    // component={Link}
                    // to="/recruiters-login"
                    >
                      {loader ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "white" }}
                          variant="determinate"
                          value={progress}
                        />
                      ) : (
                        "Reset Password"
                      )}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {(props.pageType === "HospitalSignUp" && props.source !== "recruiterhome") && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ color: "#333333", fontSize: "1rem" }}
                    >
                      Already have an Account?
                    </Typography>
                    <Button
                      variant="text"
                      // component={Link}
                      // to="/recruiters-login"
                      onClick={handleLoginNavigate}
                      sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        textDecoration: "underline",
                      }}
                    >
                      Sign In
                    </Button>
                  </Box>
                )}

                {(props.pageType === "HospitalLogIn" && props.source !== "recruiterhome") && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ color: "#333333", fontSize: "1rem" }}
                    >
                      Don't have an account?
                    </Typography>
                    <Button
                      variant="text"
                      // component={Link}
                      // to="/recruiters-signup"
                      onClick={handleSignUpNavigate}
                      sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        textDecoration: "underline",
                      }}
                    >
                      Sign Up
                    </Button>
                  </Box>
                )}
                {props.pageType === "Create New Password" && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ color: "#333333", fontSize: "1rem" }}
                    >
                      Don't have an account?
                    </Typography>
                    <Button
                      variant="text"
                      // component={Link}
                      // to="/recruiters-signup"
                      onClick={handleSignUpNavigate}
                      sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        textDecoration: "underline",
                      }}
                    >
                      Sign Up
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {success && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {forgotPassword === "true" && success}
              </Alert>
            </Snackbar>
          )}
          {successForgetPassword && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {/* {forgotPassword === 'true' && success} */}
                Your password has been updated successfully!
              </Alert>
            </Snackbar>
          )}
          {failure && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {error && error}
                {/* Congratulation! Your account has been created. */}
              </Alert>
            </Snackbar>
          )}
          {registerSuccess && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{
                  width: "100%",
                  backgroundColor: "green",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Congratulation! Your account has been created.
              </Alert>
            </Snackbar>
          )}
          {verificationCodeSent && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                A code has been sent to your email successfully.
                {/* Congratulation! Your account has been created. */}
              </Alert>
            </Snackbar>
          )}
          {signupResendCode && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                A verification code is sent again, please confirm.
                {/* Congratulation! Your account has been created. */}
              </Alert>
            </Snackbar>
          )}
          {loginSuccess && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{
                  width: "100%",
                  backgroundColor: "green",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Congratulation! Login is successful.
              </Alert>
            </Snackbar>
          )}
          {failureLogin && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{
                  width: "100%",
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Incorrect username or password.
              </Alert>
            </Snackbar>
          )}
          {userExists && (
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                An account with the given email already exists. Try another!
              </Alert>
            </Snackbar>
          )}
          {((location?.state?.history === "/post-jobs") || (location?.state?.history === "/search-candidate") || (location?.state?.history === "/staffing-solutions") || (location?.state?.history === "/branding-solutions")) && (
            <Snackbar open={open1} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Please register/login to continue.
              </Alert>
            </Snackbar>
          )}
        </SignupGrid > : <Navigate to="/recruiters-login" />
      }
    </>

  );
};

export default HospitalSignUpForm;
