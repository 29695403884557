export const months = [
  {
    value: 1,
    name: "January",
  },
  {
    value: 2,
    name: "February",
  },
  {
    value: 3,
    name: "March",
  },
  {
    value: 4,
    name: "April",
  },
  {
    value: 5,
    name: "May",
  },
  {
    value: 6,
    name: "June",
  },
  {
    value: 7,
    name: "July",
  },
  {
    value: 8,
    name: "August",
  },
  {
    value: 9,
    name: "September",
  },
  {
    value: 10,
    name: "October",
  },
  {
    value: 11,
    name: "November",
  },
  {
    value: 12,
    name: "December",
  },
];

// Currently Working Array
export const currentlyWorkingData = [
  {
    id: "1",
    currentlyWorking: "Yes"
  },
  {
    id: "0",
    currentlyWorking: "No"
  }
];

// Employment Type Array
export const employmentTypeData = [
  {
    id: 0,
    employmentType: "Full Time"
  },
  {
    id: 1,
    employmentType: "Part Time"
  },
  {
    id: 2,
    employmentType: "Locum"
  }
];

// Job Type Array
export const jobTypeData = [
  {
    id: 0,
    jobType: "Permanent"
  },
  {
    id: 1,
    jobType: "Contract"
  }
];

export const genderData = [
  {
    id: 0,
    genderOption: "Male"
  },
  {
    id: 1,
    genderOption: "Female"
  },
  {
    id: 2,
    genderOption: "Others"
  }
];

export const workStatusData = [
  {
    id: 1,
    statusType: "Yes"
  },
  {
    id: '0',
    statusType: "No"
  }

];

export const ExperienceData = [
  { title: "6 Months", value: 6, type: "expMonths" },
  { title: "1 Year", value: 1, type: "exp" },
  { title: "2 Years", value: 2, type: "exp" },
  { title: "3 Years", value: 3, type: "exp" },
  { title: "4 Years", value: 4, type: "exp" },
  { title: "5 Years", value: 5, type: "exp" },
  { title: "6 Years", value: 6, type: "exp" },
  { title: "7 Years", value: 7, type: "exp" },
  { title: "8 Years", value: 8, type: "exp" },
  { title: "9 Years", value: 9, type: "exp" },
  { title: "10-15 Years", value: 10, type: "exp" },
  { title: "15-20 Years", value: 15, type: "exp" },
  { title: "20+ Years", value: 20, type: "exp" },
];

export const Time = [
  "12 AM",
  "01 AM",
  "02 AM",
  "03 AM",
  "04 AM",
  "05 AM",
  "06 AM",
  "07 AM",
  "08 AM",
  "09 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "01 PM",
  "02 PM",
  "03 PM",
  "04 PM",
  "05 PM",
  "06 PM",
  "07 PM",
  "08 PM",
  "09 PM",
  "10 PM",
  "11 PM",
];

export const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

export const jobType = [
  "Permanent",
  "Contractual",
]

export const EmploymentType = [
  "Full Time",
  "Part Time",
  "Locum"
]

export const CommunicationPreference = [
  "Phone",
  "Email",
  "WhatsApp",
  "SMS"
]

export const UploadResumeLimit="File size should be less than 2 MB"
export const UploadResumeError=" Resume file should be either in .pdf, .doc or .docx format."
export const somethingWentWrog="Sorry, something went wrong. Please, try again."
export const resumeUploadedsuccess="Resume uploaded successfully."
export const jobseekerprofile="Jobseeker Details added successfully."
export const educationadded="Education Details added successfully."
export const Experienceadded="Experience Details added successfully."
export const registersuccess="Successfully registered your details."