import { Amplify, Hub } from "aws-amplify";
import { CONNECTION_STATE_CHANGE } from "@aws-amplify/pubsub";
import { useEffect } from "react";
import { JobseekerSubscriptions } from "./JobseekerSubscriptions";
import { RecruiterSubscriptions } from "./RecruiterSubscriptions";

const Subscriptions = ({
  getNotifications,
  notifications,
  permitUser,
  getReminders,
  reminders,
   getHospitalDetailsData,
  getUserProfile
  
}) => {
  let access_token = localStorage.getItem("accessToken");

  Hub.listen("api", (data) => {
    try {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState;
      }
    } catch (err) {
      console.log(err);
    }
  });

  if (localStorage.getItem("flow") === "jobSeeker") {
    Amplify.configure({
      Auth: {
        userPoolId: `${process.env.REACT_APP_DOCTORS_FLOW_SIGNUP_USERPOOLID}`,
        region: "ap-south-1",
      },
      aws_appsync_graphqlEndpoint: `${process.env.REACT_APP_FAQ_FLOW_GRAPHQL_MAIN_URL}`,
      aws_appsync_region: "ap-south-1",
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
      aws_appsync_apiKey: `${process.env.REACT_APP_FAQ_FLOW_X_API_KEY}`,
      aws_appsync_authorization: `Bearer ${access_token}`,
    });
  }
  if (localStorage.getItem("flow") === "hospital") {
    Amplify.configure({
      Auth: {
        userPoolId: `${process.env.REACT_APP_HOSPITAL_FLOW_SIGNUP_USERPOOLID}`,
        region: "ap-south-1",
      },
      aws_appsync_graphqlEndpoint: `${process.env.REACT_APP_FAQ_FLOW_GRAPHQL_MAIN_URL}`,
      aws_appsync_region: "ap-south-1",
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
      aws_appsync_apiKey: `${process.env.REACT_APP_FAQ_FLOW_X_API_KEY}`,
      aws_appsync_authorization: `Bearer ${access_token}`,
    });
  }

  useEffect(() => {
    if (localStorage.getItem("flow") === "hospital") {
      RecruiterSubscriptions({
        getNotifications,
        notifications,
        permitUser,
        getReminders,
        getHospitalDetailsData,
        getUserProfile
      });
    }
  }, [permitUser, reminders]);

  useEffect(() => {
    if (localStorage.getItem("flow") === "jobSeeker") {
      JobseekerSubscriptions({ getNotifications });
    }
  }, []);

  return {};
};

export default Subscriptions;
